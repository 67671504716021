header {
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-left: 50px
}

.gkl-logo-container {
    float: left;
    /* width: 275px; */
}

.gkl-logo {
    /* width: 60%; */
    width: 100px;
    height: auto;
    /* margin-left: 30%; */
    /* margin-top: 25%; */
    /* margin-bottom: 25%; */
}

nav {
    width: 100%;
    display: flex;
    justify-content: right;
    padding-right: 50px;;
}

nav > form {
    float: right;
    border: none;
    background: none;
    /* width: 10%; */
    width: fit-content;
    padding-left: 1%;
    padding-right: 1%;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

nav > form > input {
    font-family: 'Chakra-Petch-Semi-Bold';
    background: transparent;
    color: white;
    font-size: 0.8em;
    border-bottom: 2px solid transparent;
    border-right: 0px;
    border-left: 0px;
    border-top: 0px;
    cursor: pointer;
    /* border-radius: 5px; */
}

nav > form > input.selected {
    color:rgb(25, 240, 45);
    border-bottom: 2px solid;
    /* border-right: 0px; */
    /* border-left: 0px; */
    /* border-radius: 5px; */
}

nav > form > input:hover {
    border-bottom: 2px solid;
    /* border-right: 0px; */
    /* border-left: 0px; */
}

.menu-container {
    display: inline-block;
}

/* .menu-container > input {
    background: rgb(28, 28, 28) url(/static/img/down-arrow.png) right no-repeat;
    background-size: 20px;
    padding-right: 30px;
    color: white;
    border: 2px solid;
    border-radius: 5px;
} */

.menu-input {
    font-size: 0.9em;
    cursor: pointer;
    font-family: 'Chakra-Petch-Semi-Bold';
}

.menu-unpressed {
    background: rgb(28, 28, 28) url(../img/down-arrow.png) right 7.5px top 45% no-repeat;
    background-size: 15px;
    padding-right: 30px;
    color: white;
    border: 2px solid;
    border-radius: 5px;

}

.menu-pressed {
    background: rgb(28, 28, 28) url(../img/up-arrow.png) right 7.5px top 45% no-repeat;
    background-size: 15px;
    padding-right: 30px;
    color: white;
    border: 2px solid;
    border-radius: 5px;

}

.bar1, .bar2, .bar3, .bars {
    width: 35px;
    height: 5px;
    background-color: rgb(25, 240, 45);
    margin: 6px 0;
    transition: 0.4s;
}

.bar1.bars.show-menu {
    transform: translate(0, 11px) rotate(-45deg);
}
.bar2.bars.show-menu {opacity: 0;}
.bar3.bars.show-menu {
    transform: translate(0, -11px) rotate(45deg);
}

.user-options-container {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    width: fit-content;
}

/* Style The Dropdown Button */
.dropbtn {
    background-color:transparent;
    color: black;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    font-family: 'Chakra-Petch-Semi-Bold';
    font-size: 0.8em;
}
    
/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    /* display: inline-block; */
    display: flex;
    justify-content: right;
}
    
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    /* transform: translate(-50%, 0%); */
    display: none;
    position: absolute;
    background-color: transparent;
    color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 10px;
    text-align: right;
    margin-top: 30px;
    margin-right: 10px;
}
    
/* Links inside the dropdown */
.dropdown-content a {
    color: white;
    padding: 3px 3px;
    text-decoration: none;
    display: block;
    font-family: 'Chakra-Petch-Semi-Bold';
    border: 2px solid transparent;
    border-right: 0px;
    border-left: 0px;
    font-size: 0.7em;
    margin-bottom: 5px;
    text-align: center;
    cursor: pointer;
}

.dropdown-content a.selected {
    color:rgb(25, 240, 45);
    border-bottom: 2px solid;
    border-right: 0px;
    border-left: 0px;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: transparent;
    border-bottom: 2px solid;
    border-right: 0px;
    border-left: 0px;
}
    
/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: transparent;
}

.dropdown-content a.mobile-nav-link {
    display: none;
}

@media only screen and (max-width: 880px) {
    header {
        /* display: block; */
        margin-top: 10px;
        margin-left: 0px;
    }

    .gkl-logo-container,
    nav {
        width: 100%;
        display: flex;
    }

    nav {
        padding-right: 0;
    }

    .gkl-logo-container {
        float: left;
        display: flex;
        justify-content: left;
    }

    .gkl-logo-container > a {
        width: 100px;
    }
    .gkl-logo {
        width: 100%;
        padding: 5px;
        margin-left: 10px;
    }

    nav > form {
        float: left;
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: center;
    }

    .desktop-nav-link {
        display: none;
    }

    .user-options-container {
        margin-right: 10px;
    }

    .dropdown-content {
        background: black;
        border: 1px solid rgb(99, 99, 99);
        /* border-top: none; */
        border-radius: 0px 0px 10px 10px;
        margin-top: 25px;
        margin-right: 7px;
    }

    .dropdown-content a.mobile-nav-link {
        display: block;
    }    
}