.talent-id-name-welcome {
    font-family: 'Chakra-Petch-Bold';
    font-size: 5em;
    color: white;
    text-align: center;
}

.dashboard-page main {
    /* padding-top: 2.5%; */
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    /* height: 70vh; */
}

.dashboard-page main > p {
    width: 30%;
    height: auto;
    text-align: center;
}

.dashboard-page .field-container {
    display: flex;
    align-items: center;
    justify-content: right;
}

.dashboard-page .field-container > p > input {
    background-color: lightslategrey;
    border-color: white;
    height: 40px;
    border-radius: 10px;
}

.dashboard-page .field-container > p > select {
    background-color: lightslategrey;
    border-color: white;
    height: 40px;
    border-radius: 10px;
}

.dashboard-page .centre-field-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-page .centre-field-container > p > input {
    background-color: lightslategrey;
    border-color: white;
    border-radius: 10px;
}

.dashboard-page .centre-field-container > p > button {
    background-color: lightslategrey;
    border-color: white;
    border-radius: 10px;
}

.filters {
    display: flex;
    padding-left: 2.5%;
    padding-top: 2.5%;
}

.event-number {
    width: 100%;
    justify-content: center;
}

.event-selection {
    display: flex;
    align-items: center;
    width: min(20%, 150px);
}

.event-selection > div {
    width: 100%;
    text-align: right;
}

.event-selection > div > input {
    background-color: lightslategrey;
    border-color: white;
    height: 40px;
    border-radius: 10px;
    width: 100%;
    color: black;
    font-family: 'Chakra-Petch-Semi-Bold';
}

.selection {
    display: flex;
    align-items: center;
    width: 100%;
}

.selection > form {
    width: 100%;
}

.selection > form > input {
    background-color: lightslategrey;
    border-color: white;
    height: 40px;
    border-radius: 10px;
    width: 100%;
    color: black;
    font-family: 'Chakra-Petch-Semi-Bold';
}

.selection-container {
    display: flex;
    align-items: center;
    justify-content: right;
    float: left;
    padding: 10px;
}

.selection-container > p > input {
    background-color: lightslategrey;
    border-color: white;
    height: 40px;
    border-radius: 10px;
}

.selection-container > p > select {
    background-color: lightslategrey;
    border-color: white;
    height: 40px;
    border-radius: 10px;
}

.selection-centre-field-container {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selection-centre-field-container > p > input {
    background-color: lightslategrey;
    border-color: white;
    border-radius: 10px;
}

.selection-centre-field-container > p > button {
    background-color: lightslategrey;
    border-color: white;
    border-radius: 10px;
}

.auth-errors {
    color: red;
    font-size: 0.8em;
    display: flex;
    justify-content: right;
}

.password-constraints-centred {
    text-align: center;
    margin-bottom: 20px;
}

.glyphicon-remove {
    color: red;
}

.glyphicon-ok {
    color: green;
}

.charts {
    padding-left: 40px;
    display: flex;
    padding-bottom: 40px;
}

.axis path {
    stroke: white;
}

.axis line {
    stroke: white;
}

.axis text {
    fill: white;
    font-family: 'Chakra-Petch-Semi-Bold-Italic';
}

.kpi-section {
    float: left;
}
.kpi-section-padded {
    padding: 2.5%;
}
.pie-kpi-section {
    padding-right: 100px;
    padding-left: 100px;
}

.pie-chart-container {
    padding-bottom: 20px;
}

.stats-wrapper {
    width: 95%;
}

.stats-title {
    font-family: 'Chakra-Petch-Regular';
}

.statistics-container {
    display: flex;
    justify-content: left;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 2px rgb(25, 240, 45) solid;
    padding-top: 10px;
    /* border-top: 2px rgb(25, 240, 45) solid; */
    align-items: center;
    overflow-x: auto;
}

.statistic {
    padding: 5px;
    text-align: center;
    background-color: rgb(46, 46, 46);
    border: 1px solid white;
    border-radius: 10px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.statistic-name {
    font-family: 'Chakra-Petch-Semi-Bold';
    font-size: 0.8em;
    text-wrap: wrap;
    white-space: wrap;
    height: 45px;
    width: 100px;
    display: flex;
    align-items: start;
    justify-content: center;
}

.statistic-value {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    min-width: max-content;
}

.statistic-numeric {
    font-family: 'Chakra-Petch-Bold';
    font-size: 3em;
    color:rgb(25, 240, 45);
}

.statistic-units {
    font-family: 'Chakra-Petch-Regular';
    font-size: 0.8em;
    color:rgb(25, 240, 45);
    height: 16px;
}

.insights-wrapper {
    width: 95%;
    /* margin-top: 10px; */
}

.insights-title {
    font-family: 'Chakra-Petch-Regular';
}

.insights-container {
    /* display: flex; */
    /* justify-content: left; */
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 2px rgb(25, 240, 45) solid;
    padding-top: 20px;
    /* border-top: 2px rgb(25, 240, 45) solid; */
    /* height: 75vh; */
    /* height: 450px; */
    /* align-items: center; */
}

.events-insights-container {
    height: max(83vh, 800px);
}

.inline-containers {
    display: flex;
    justify-content: left;
    width: 100%;
    /* height: 100%; */
}

.events-inline-containers {
    height: 100%;
}

.metric-options-container {
    float: left;
    margin-right: 20px;
}

.events-metric-options-container {
    width: 300px;
    margin-right: 2.5%;
}

.metric-name {
    padding: 5px;
}

.event-metric-name {
    width: 100%;
    display: flex;
    justify-content: left;
}

.metric-name > button {
    font-family: 'Chakra-Petch-Semi-Bold';
    background: transparent;
    color: white;
    border: none;
    border-bottom: 2px transparent solid;
    width: max-content;
    border-radius: 0px;
}

.metric-name > button:hover {
    border-bottom: 2px white solid;
}

.metric-name .selected {
    color:rgb(25, 240, 45);
    border-bottom: 2px rgb(25, 240, 45) solid;
}

.metric-name .selected:hover {
    border-bottom: 2px rgb(25, 240, 45) solid;
}


.insights-chart-container {
    float: left;
    width: 90%;
    /* height: 100%; */
}

.events-insights-chart-container {
    height: 100%;
}

.insights-chart {
    display: none;
    /* height: 100%; */
}

.events-insights-chart {
    height: 100%;
}

/* .insights-chart-content {
    height: 90%;
    padding-top: 2.5%;
} */

.events-insights-chart-content {
    height: 90%;
    padding-top: 2.5%;
}

/* .insights-chart-plot {
    height: 100%;
} */

.events-insights-chart-plot {
    height: 100%;
}

.overview-plot {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.insights-chart-name {
    font-family: 'Chakra-Petch-Bold';
    font-size: 1.2em;
    padding-bottom: 3px;
    color: rgb(25, 240, 45);
    display: flex;
    align-items: center;
}

.insights-chart-toggle {
    margin-left: 20px;
    /* position: absolute; */
    /* right: 50px; */
    display: flex;
    align-items: center;
}

.insights-chart-description {
    font-family: 'Chakra-Petch-Bold';
    font-size: 1em;
    padding-bottom: 3px;
    color: rgb(99, 99, 99);
}

.event-number-selection-container {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: right;
    /* margin-top: 30px; */
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    /* width: 100%; */
    background:rgb(99, 99, 99);
    border-radius: 5px;
    font-family: 'Chakra-Petch-Regular';
    font-size: 0.8em;
}

.event-number-selection {
    font-size: 12px;
    /* padding: 12px; */
    position: absolute;
    right: 3%;
    margin-top:1%;
}

.event-number-display {
    color: white;
    opacity: 0.8;
}

.dashboard-page label {
    margin-right: 10px;
}

select {
    border-radius: 5px;
    padding: 3px;
    font-family: 'Chakra-Petch-Regular';
    background-color: rgb(139, 139, 139);
    color: white;
    opacity: 0.8;
}

.metric-select {
    margin: 5px;
    width: 100%;
    cursor: pointer;
}

.metric-button {
    border-radius: 5px;
    padding: 3px;
    margin: 5px;
    width: 100%;
    font-family: 'Chakra-Petch-Regular';
    background-color: transparent;
    color: white;
    /* opacity: 0.8; */
    border: 2px rgb(25, 240, 45) solid;
    font-size: 0.8em;
    cursor: pointer;
}

#lap-time-chart {
    position: relative;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.axis-grid line {
    stroke:white;
    opacity: 0.2;
}

.axis-grid path {
    stroke: rgb(0,0,0);
}

.rank-container {
    justify-content: center;
    width: 170px;
    border-radius: 20px;
    border: 2px white solid;
    margin-left: 20px;
    margin-top: 30px;
    height: fit-content;
}

.rank-title {
    font-family: 'Chakra-Petch-Semi-Bold';
    font-size: 1em;
    padding-bottom: 3px;
    color: rgb(99, 99, 99);
    display: flex;
    justify-content: center;
}

.rank-value {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
}

.rank-numeric {
    font-family: 'Chakra-Petch-Bold';
    font-size: 4em;
    color:rgb(25, 240, 45);
}

.rank-units {
    font-family: 'Chakra-Petch-Regular';
    font-size: 1em;
    margin-top: 15px;
    align-self: flex-start;
}

.rank-max {
    align-self: flex-end;
    margin-bottom: 20px;
    font-family: 'Chakra-Petch-Semi-Bold';
}

.stats-title-wrapper {
    display: flex;
    justify-content: space-between;
}

.stats-img {
    width: 20px;
}

.insights-title-wrapper {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px rgb(25, 240, 45) solid;
    cursor: pointer;
}

.insights-img {
    width: 20px;
    transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
}

.flipped {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
}

  .toggle {
    --width: 50px;
    --height: calc(var(--width) / 2);
    position: relative;
    display: inline-block;
    width: var(--width);
    height: var(--height);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: var(--height);
    cursor: pointer;
  }

  .toggle input {
    display: none;
  }

  .toggle .slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--height);
    background-color: #ccc;
    transition: all 0.4s ease-in-out;
  }

  .toggle .slider::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(var(--height));
    height: calc(var(--height));
    border-radius: calc(var(--height) / 2);
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    transition: all 0.4s ease-in-out;
  }

  .toggle input:checked+.slider {
    background-color: rgb(25, 240, 45);
  }

  .toggle input:checked+.slider::before {
    transform: translateX(calc(var(--width) - var(--height)));
  }

  .toggle .labels {
    position: absolute;
    top: calc(var(--height) / 6);
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-family: 'Chakra-Petch-Semi-Bold-Italic';
    transition: all 0.4s ease-in-out;
  }

  .toggle .labels::after {
    content: attr(data-off);
    position: absolute;
    right: 5px;
    color: #4d4d4d;
    opacity: 1;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    transition: all 0.4s ease-in-out;
  }

  .toggle .labels::before {
    content: attr(data-on);
    position: absolute;
    left: 5px;
    color: #4d4d4d;
    opacity: 1;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
    transition: all 0.4s ease-in-out;
  }

  .toggle input:checked~.labels::after {
    opacity: 1;
  }

  .toggle input:checked~.labels::before {
    opacity: 1;
  }

.information-container {
    display: none;
}

.information-icon {
    width: 30px;
    margin: 10px;
    height: 30px;
}
.insights-information-icon {
    height: 25px;
    margin-left: 20px;
}

.popup {
    display: none;
    position: absolute;
    left: 60px;
    margin-top: 15px;
    background: rgb(99, 99, 99);
    color: white;
    padding: 10px;
    border-radius: 10px;
    z-index: 1;
    font-family: 'Chakra-Petch-Regular';
    width: 50%;
    text-wrap: wrap;
}

.insights-popup {
    left: auto;
    margin-top: 30px;
}

.show {
    display: block;
}

/* .insights-chart-title {
    height: 100px;
} */

@media only screen and (max-width: 880px) {
    .events-insights-container {
        height: max(86vh, 800px);
    }
}

@media only screen and (max-width: 720px) {
    /* .statistic {
        width: auto;
    } */
    .dashboard-page .inline-containers {
        display: block;
    }
    .events-insights-container {
        height: max(100vh, 800px);
    }
    .insights-container {
        padding-top: 0;
    }
    .metric-name {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .metric-options-container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .metric-button {
        margin: 0;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .metric-select {
        width: 100%;
        margin: 0;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .metric-options {
        display: flex;
        overflow-x: auto;
    }
    .metric-name > button {
        text-align: center;
    }
    .overview-plot {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
    }
    .insights-chart-title {
        display: none;
    }
    .insights-chart-description {
        font-size: 0.8em;
        padding-bottom: 0;
        margin-right: 5px;
    }
    .event-number-selection {
        position: static;
        display: flex;
        align-items: center;
        margin-top: 8px;
        margin-left: 10px;
        order: 2;
    }
    .statistic-numeric {
        font-size: 1em;
    }
    .statistic-value {
        width: 100px;
    }
    .statistic-name {
        height: auto;
        text-wrap: nowrap;
        white-space: nowrap;
        width: auto;
    }
    .insights-chart-container {
        width: 100%;
    }
    .information-container {
        display: flex;
    }
    .popup {
        font-size: 0.8em;
    }
}