.account-page main {
    height: 100vh;
}

.account-container {
    padding: 10%;
    padding-top: 5%;
}

.account-body-container-body-field {
    display: flex;
    padding: 1.25%;
}

.account-body-container-body-field-value {
    padding-left: 2.5%;
    color: rgb(25, 240, 45);
}

.account-img {
    padding: 1%;
}

.avatar {
    width: min(20%, 125px);
}

.account-body-change-password > form {
    font-family: 'Chakra-Petch-Semi-Bold';
    border: none;
    background: none;
    padding: 1.25%;
}

.account-body-change-password > form > input {
    background: transparent;
    color: white;
    font-size: 1.2em;
    border-bottom: 2px white solid;
    border-top: none;
    border-left: none;
    border-right: none;
    font-family: 'Chakra-Petch-Semi-Bold';
    cursor: pointer;
}

.account-body-change-password > form > input:hover {
    border-bottom: 2px rgb(25, 240, 45) solid;
}

@media only screen and (max-width: 670px) {
    .account-container {
        padding-top: 35%;
    }

    .account-page h3 {
        font-size: 4vw;
    }
}