footer {
    /* position: fixed;
    left: 0;
    bottom: 0; */
    margin-top: auto;
    width: 100%;
    font-size: 0.8em;
}

.socials-container {
    width: 100%;
    display: inline-flex;
    justify-content: right;
}

.social-wrapper {
    padding: 2.5%;
    padding-bottom: 1%;
}

.social-img {
    width: 20px;
}

/* img {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
}

a:hover img {
    filter: none;
    -webkit-filter: grayscale(0);
} */

.grid-container {
    display: flex;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-bottom: 15px;
    border-bottom: 2px rgb(25, 240, 45) solid;
    border-top: 2px rgb(25, 240, 45) solid;
    position: relative;
}

.column {
    width: 33%;
}

.left {
    padding-bottom: 30px;
    padding-top: 30px;
}

.centre {
    position: absolute;
    left: 33%;
    padding-top: 20px;
    /* bottom: 0; */
}

.right {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-bottom: 15px;
}

.para {
    font-family: 'Chakra-Petch-Regular';
}

footer a {
    cursor: pointer;
}

.left > a, .left > .para {
    display: block;
    text-align: left;
    color: white;
    width: fit-content;
}

.left > a > img {
    width: 150px;
    margin-left: 10px;
}

.support {
    text-decoration: none;
    color: white;
    /* padding-left: 5%; */
    padding-top: 2.5%;
    padding-left: 10px;
}

.support > div {
    width: fit-content;
    border-bottom: 2px solid;
}

.centre > a, .centre > .para {
    display: block;
    text-align: left;
    color: white;
    padding: 5px;
}

.centre > a {
    text-decoration: none;
    color: white;
    width: fit-content;
}

.centre > a > div {
    width: fit-content;
    border-bottom: 2px solid;
}

.right > a, .right > .para {
    display: block;
    text-align: right;
    color: white;
}

.right > .para {
    margin: 0;
    padding-right: 10px;
}

.socials {
    display: flex;
    justify-content: right;
    padding-bottom: 15px;
}

.social-link {
    padding: 10px;
    opacity: 0.5;
}

.social-link:hover {
    opacity: 1;
}

.short-contact-email {
    display: none;
}

@media only screen and (max-width: 670px) {
    footer {
        font-size: 0.8em;
    }
    .grid-container {
        margin-left: 10px;
        margin-right: 10px;
    }
    .left {
        width: 35%;
        padding-top: 32px;
        padding-bottom: 32px;
    }
    .full-contact-email {
        display: none;
    }
    .short-contact-email {
        display: block;
    }
    .centre {
        width: 40%;
        left: 36%;
        padding-top: 0;
    }
    .right {
        width: 25%;
    }
    .left > a > img {
        width: 100px;
        margin-left: 0;
        padding-left: 0;
    }
    .support {
        padding-left: 0;
    }
    .social-link {
        padding: 5px;
    }
}