.feedback-page main {
    height: 100vh;
    display: grid;
    justify-content: center;
    grid-template-columns: 100%;
    grid-template-rows: max-content;
}

.feedback-page main > div {
    width: 100%;
    height: fit-content;
}

.feedback-page main > div > h3 {
    margin-top: 20px;
    text-align: center;
}

.feedback-page main > div > p {
    display: block;
    width: 90%;
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
}

.feedback-page main > div > p > a {
    color: white;
    text-decoration: none;
    border-bottom: 2px rgb(25, 240, 45) solid;
}

.feedback-page main > form {
    width: 100%;
}

.feedback-page .grid-inputs {
    display: grid;
    grid-gap: 5%;
    grid-template-columns: 45% 45%;
    padding: 2.5%;
    justify-content: center;
    text-align: right;
}

.feedback-page .field-container {
    border: 2px rgb(25, 240, 45) solid;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    text-align: right;
    align-items: center;
}

.feedback-page p {
    display: flex;
    justify-content: center;
    text-align: right;
    padding: 0;
    margin: 10px;
    width: 100%;
}

.feedback-page label {
    padding-right: 10px;
}

.feedback-page select {
    border: 1px white solid;
    background: transparent;
    color: rgb(25, 240, 45);
    border-radius: 5px;
}

.feedback-page .comments-container {
    margin: 5%;
    border: none;
}

.feedback-page .comments-container > p {
    display: grid;
    text-align: left;
    width: 100%;
    grid-template-columns: 100%;

}

.feedback-page .comments-container > p > textarea {
    background: transparent;
    width: 100%;
    height: 100px;
    border: 2px rgb(25, 240, 45) solid;
    border-radius: 10px;
    color: white;
    resize: none;
}

.feedback-page .centre-field-container > p > input {
    background: transparent;
    font-size: 1em;
    color: white;
    border-bottom: 2px rgb(25, 240, 45) solid;
    border-right: 0px;
    border-left: 0px;
    border-top: 0px;
    font-family: 'Chakra-Petch-Bold';
}