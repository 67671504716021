@font-face {
    font-family: 'Chakra-Petch-Regular';
    src: url('../fonts/ChakraPetch-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Chakra-Petch-Bold';
    src: url('../fonts/ChakraPetch-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Chakra-Petch-Semi-Bold';
    src: url('../fonts/ChakraPetch-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Chakra-Petch-Semi-Bold-Italic';
    src: url('../fonts/ChakraPetch-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
}

body {
    background-color: black;
    color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /* background-image: repeating-linear-gradient(-45deg, black, black 1px, black 1px, rgb(30, 30, 30) 6px);
    background-size: 4px 4px; */
}

h1 {
    font-family: 'Chakra-Petch-Bold';
}

h2, h3, h4, h5, h6 {
    font-family: 'Chakra-Petch-Semi-Bold';
}

p, button, form, a, select {
    font-family: 'Chakra-Petch-Regular';
}

label {
    font-family: 'Chakra-Petch-Semi-Bold';
}

.semi-bold-italic {
    font-family: 'Chakra-Petch-Semi-Bold-Italic';
}

.footer-padding {
    height: 40vh;
}

.cookies-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.cookies-banner-content {
    align-items: center;
    width: 100%;
}

.cookies-banner-content-text {
    display: flex;
    justify-content: center;
}

.cookies-banner-content-text > p > a {
    color: white;
    text-decoration: none;
    font-family: 'Chakra-Petch-Semi-Bold';
    border-bottom: 2px solid rgb(25, 240, 45);
}

.cookies-banner-content-buttons {
    display: flex;
    justify-content: center;
}

.cookies-banner-button {
    padding: 10px;
    border: none;
    cursor: pointer;
}

.accept {
    background-color: rgb(25, 240, 45);
    color: black;
    margin-right: 10px;
    border: 1px solid white;
}

.decline {
    background-color: rgb(99,99,99);
    color: white;
    border: 1px solid white;
}