.auth-page main {
    padding-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    /* justify-content: center; */
    /* height: 100vh; */
    /* transform: translate(0, 75%); */
}

.auth-page main > p {
    width: 30%;
    height: auto;
    text-align: center;
}

.auth-page label {
    padding-right: 5px;
}

.auth-page .field-container {
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: 40px;
}

/* .field-container > p > input {
    background-color: lightslategrey;
    border-color: white;
    height: 40px;
    border-radius: 10px;
} */

.auth-page .field-container > p > input {
    font-family: 'Chakra-Petch-Regular';
    font-size: 1em;
    color: white;
    --c: rgb(25, 240, 45);
    height:50px;
    width: 400px;
    padding:18px;
    box-sizing:border-box;
    border:none;
    outline:0;
    background:
      linear-gradient( 45deg,#0000 9.90px, var(--c) 0 calc(9.90px +  3px), #0000 0 calc(100% - 9.90px -  3px), var(--c) 0 calc(100% - 9.90px), #0000 0),
      linear-gradient(-45deg,#0000 9.90px, var(--c) 0 calc(9.90px +  3px), #0000 0 calc(100% - 9.90px -  3px), var(--c) 0 calc(100% - 9.90px), #0000 0),
      linear-gradient(90deg ,var(--c) 6px,#0000 0) -3px 50%/100% calc(100% - 28px) repeat-x,
      linear-gradient(180deg,var(--c) 6px,#0000 0) 50% -3px/calc(100% - 28px) 100% repeat-y
}

.auth-page .centre-field-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .centre-field-container > p > input {
    background-color: lightslategrey;
    border-color: white;
    border-radius: 10px;
} */

/* .centre-field-container > p > input {
    color: rgb(25, 240, 45);
    --c: rgb(25, 240, 45);
    height:50px;
    padding:18px;
    box-sizing:border-box;
    border:none;
    outline:0;
    background:
      linear-gradient( 45deg,#0000 9.90px, var(--c) 0 calc(9.90px +  3px), #0000 0 calc(100% - 9.90px -  3px), var(--c) 0 calc(100% - 9.90px), #0000 0),
      linear-gradient(-45deg,#0000 9.90px, var(--c) 0 calc(9.90px +  3px), #0000 0 calc(100% - 9.90px -  3px), var(--c) 0 calc(100% - 9.90px), #0000 0),
      linear-gradient(90deg ,var(--c) 6px,#0000 0) -3px 50%/100% calc(100% - 28px) repeat-x,
      linear-gradient(180deg,var(--c) 6px,#0000 0) 50% -3px/calc(100% - 28px) 100% repeat-y
} */

.auth-page .centre-field-container > p > input {
    background: transparent;
    /* color: white; */
    font-size: 1em;
    color: white;
    border-bottom: 2px rgb(25, 240, 45) solid;
    border-right: 0px;
    border-left: 0px;
    border-top: 0px;
    border-radius: 0;
    font-family: 'Chakra-Petch-Bold';
    cursor: pointer;
    /* border-radius: 5px; */
}

.auth-page .centre-field-container > p > form > input {
    background: transparent;
    /* color: white; */
    font-size: 1em;
    color: white;
    border-bottom: 2px rgb(25, 240, 45) solid;
    border-right: 0px;
    border-left: 0px;
    border-top: 0px;
    font-family: 'Chakra-Petch-Bold';
    /* border-radius: 5px; */
}

.auth-page .remember-me {
    display: flex;
    align-items: center;
}

.auth-page .remember-me > label {
    padding-right: 10px;
    font-family: 'Chakra-Petch-Regular';
}

.auth-page .change > p > input{
    opacity: 0.5;
}

.auth-page .auth-errors {
    color: red;
    display: flex;
    justify-content: center;
    height: 40px;
}

.auth-page .auth-successes {
    color: rgb(25, 240, 45);
    display: flex;
    justify-content: center;
    height: 40px;
}

.auth-page .password-constraints-centred {
    text-align: center;
    margin-bottom: 20px;
}

.auth-page .glyphicon-remove {
    color: rgb(204, 38, 38);
}

.auth-page .glyphicon-ok {
    color: rgb(25, 240, 45);
}

@media only screen and (max-width: 670px) {
    .auth-page .field-container {
        padding-left: 2.5%;
        padding-right: 2.5%;
        width: 100%;
    }
    .auth-page .field-container > p, .auth-page .field-container > p > input {
        width: 100%;
    }
    .auth-page .auth-errors, .auth-successes {
        height: auto;
        width: 95%;
        margin-left: 2.5%;
        margin-right: 2.5%;
    }
}