.help-page main {
    min-height: 100vh;
}

.help-container {
    display: grid;
    grid-column-gap: 5%;
    grid-template-columns: 47.5% 47.5%;
    padding: 2.5%;
    font-size: 0.9em;
}

.help-page h4 {
    color: rgb(25, 240, 45);
    font-size: 1.2em;
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.last-column {
    grid-column: 1 / -1;
}

.help-page strong {
    border-bottom: 2px rgb(25, 240, 45) solid;
}

@media only screen and (max-width: 670px) {
    .help-container {
        display: block;
    }
    .help-page p {
        text-align: justify;
    }
}