.privacy-container {
    padding: 2.5%;
}
.privacy-container > ul {
    font-family: 'Chakra-Petch-Regular';
}
.privacy-container > p > a {
    color: white;
    text-decoration: none;
    border-bottom: 2px rgb(25, 240, 45) solid;
}
/* .help-section {
    float: left;
    padding: 2.5%;
    width: 45%;
} */