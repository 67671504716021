.talent-id-name-welcome {
    font-family: 'Chakra-Petch-Bold';
    /* font-family: 'IBMPlexSans-Bold'; */
    /* font-size: 10em; */
    font-size: 2.5em;
    color: white;
    text-align: center;
    display: flex;
    margin: 0;
    /* color:rgb(25, 240, 45); */
}

.padding {
    margin-top: 30%;
}

.white {
    color: white;
}

.green {
    color:rgb(25, 240, 45);
}

.first-page {
    scroll-snap-type: y mandatory;
}

.welcome-page main {
    /* padding-top: 5%; */
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    /* height: 100vh; */
    /* padding-left: 5%; */
    padding-left: 50px;
    position: absolute;
    bottom: 40px;
    scroll-snap-align: start;
}

.welcome-page main > p {
    width: 30%;
    height: auto;
    text-align: center;
    display: none;
}

.welcome-to {
    font-family: "Chakra-Petch-Bold";
    font-size: 1.2em;
    color: white;
    margin-bottom: 0;
}

.description {
    padding-top: 30px;
    font-family: "Chakra-Petch-Bold";
    font-size: 0.86em;
    color: white;
}

.welcome-image {
    width: 100%;
    height: 65vh;
    background-image: url("../img/GKL_Welcome.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    box-shadow: 0 0 20px 8px black inset;
    z-index: -1;
    scroll-snap-align: start;
}

.welcome-description {
    margin-left: 50px;
    margin-right: 50px;
    height: 100vh;
    scroll-snap-type: y mandatory;
}

.description-container {
    scroll-snap-align: start;
}

@media only screen and (max-width: 670px) {
    .welcome-page main {
        padding-left: 10px;
    }
    .welcome-image {
        height: 72vh;
    }
    .talent-id-name-welcome {
        font-size: 2em;
    }
}